import { Avatar, Space, Tooltip, Typography } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { Link } from "react-router-dom";

export const getFirstChar = (firstName, lastname) => {
  if (!firstName || !lastname) return;
  return firstName.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
};

const CustomTooltip = ({ title, children, color = "#c9184a", placement = "top" }: any) => {
  return (
    <Tooltip placement={placement} title={title} color={color}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
