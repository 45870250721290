import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Layout, Space, Spin, Tooltip, Typography } from "antd";
import { Footer } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { useEffect, useState, useMemo } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getMonthlySummary } from "../../services/timelog.crud";
import { useAuthValue } from "../../context/AuthContext";
import CustomTooltip from "../../components/reusableComponent/Tooltip";

const localizer = dayjsLocalizer(dayjs);

export const MonthlySummary = () => {
  const { Text } = Typography;
  const { currentUser } = useAuthValue();
  const [monthData, setMonthData] = useState(null);
  const [eventData, setEventsData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  
  const getEventData = ({ hours, startDate, leave, holiday,halfDay }, index) => ({
    id: index,
    title: hours,
    start: dayjs(startDate, "YYYY/MM/DD"),
    end: dayjs(startDate, "YYYY/MM/DD"),
    leave,
    holiday,
    halfDay,
    allday: false,
  });

  useEffect(() => {
    const fetchMonthlySummary = async () => {
      setIsLoading(true);
      try {
        const res = await getMonthlySummary(currentUser.uid, dayjs(date).format("DD/MM/YYYY"));
        const filterData = res.timelog.map(getEventData).filter((item) => item.title !== "0.00");

        setEventsData(filterData);
        setMonthData(res);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMonthlySummary();
  }, [date, currentUser.uid]);

  

  const dayPropGetter = (date) => {
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
    const isLeave = eventData.some(
      (event) => new Date(event.start).toDateString() === date.toDateString() && event.leave,
    );
    const isHoliday = eventData.some(
      (event) => new Date(event.start).toDateString() === date.toDateString() && event.holiday,
    );
    const isHalfDay = eventData.some(
      (event) => new Date(event.start).toDateString() === date.toDateString() && event.halfDay,

    );

    return {
      style: {
        backgroundColor: isWeekend ? "#e6e6e6" : isLeave ? "#c9184a80" : isHoliday && !isHalfDay ? "#e6e6e6" : isHalfDay ? "rgb(240 237 185)": undefined,
        color: isWeekend ? "#ccc" : undefined,
      },
    };
  };

  return (
    <Layout
      style={{
        background: "transparent",
        display: "flex",
        justifyContent: "center",
        paddingInline: "20vw",
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      {isLoading && (
        <Space style={{ position: "absolute", left: "50%", zIndex: 111 }}>
          <Spin />
        </Space>
      )}
      <Calendar
        views={{ month: true }}
        selectable
        localizer={localizer}
        defaultDate={date}
        defaultView="month"
        events={eventData}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "66vh", width: "58vw" }}
        components={{
          toolbar: CustomToolbar,
          month: { event: MonthEvent },
        }}
        onNavigate={setDate}
        dayPropGetter={dayPropGetter}
        eventPropGetter={() => ({
          style: {
            display: "flex",
            fontWeight: 400,
            justifyContent: "center",
            alignItems: "center",
            fontSize: "28px",
            background: "transparent",
            color: "black",
            marginTop: 8,
            padding: 0,
          },
        })}
      />
      <Footer
        style={{
          background: "#c9184a",
          color: "white",
          fontWeight: "bold",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "58vw",
        }}
      >
        {monthData && (
          <>
            <Text style={{color:"white"}}>Total Working Days: {monthData.workingDays}</Text>
            <Text style={{color:"white"}}>Total Hours: {monthData.totalWorkHour}</Text>
            <Text style={{color:"white"}}>
              Shortage:
              <Text type="warning">
                {monthData.shortage > 0 ? monthData.shortage : `Extra ${Math.abs(monthData.shortage)}`} Hours
              </Text>
            </Text>
          </>
        )}
      </Footer>
      <Space direction="horizontal" style={{ marginTop: 8 }}>
        <Space>
          <div style={{ background: "#c9184a", height: 15, width: 15, borderRadius: 15 }} />
          <Text strong>Leave</Text>
        </Space>
        <Space>
          <div style={{ background: "#e6e6e6", height: 15, width: 15, borderRadius: 15 }} />
          <Text strong>Holiday</Text>
        </Space>
        <Space>
          <div style={{ background: "rgb(240 237 185)", height: 15, width: 15, borderRadius: 15 }} />
          <Text strong>Halfday</Text>
        </Space>
      </Space>
    </Layout>
  );
};

function CustomToolbar({ onNavigate, label }) {
  return (
    <Space direction="horizontal" style={{ justifyContent: "center", marginBottom: 16 }}>
      <Button type="text" onClick={() => onNavigate("PREV")}>
        <LeftOutlined />
      </Button>
      <div>{label}</div>
      <Button type="text" onClick={() => onNavigate("NEXT")}>
        <RightOutlined />
      </Button>
    </Space>
  );
}

function MonthEvent({ event }) {
  return (
    <Typography.Text
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",

        color: "black",
        padding: 0,
      }}
    >
      {event.leave || event.holiday ? (
        <CustomTooltip title={event.leave || event.holiday}>
          <Typography.Text style={{ padding: "50px", fontWeight: "bold" }}></Typography.Text>
        </CustomTooltip>
      ) : (
        <Typography.Text style={{ fontSize: "28px" }}>{event.title}</Typography.Text>
      )}
    </Typography.Text>
  );
}
