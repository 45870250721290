import { Avatar, Space, Typography } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { Link } from "react-router-dom";

export const getFirstChar = (firstName, lastname) => {
  if (!firstName || !lastname) return;
  return firstName.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
};

const AvatarImg = ({ userId, fullName = true, size = 32, fontSize = 14, redirect = true }) => {
  const users = useAppSelector((state) => state.userState.user);
  const user = users.find((item) => item.id === userId);

  return (
    user && (
      <>
        {redirect === true ? (
          <Link to={`/user/${userId}`}>
            <Space>
              <Avatar size={size} style={{ fontSize }} src={user.profileImage}>
                {getFirstChar(user.firstName, user.lastName)}
              </Avatar>
              {fullName === true && <Typography.Text>{`${user?.firstName} ${user?.lastName}`}</Typography.Text>}
            </Space>
          </Link>
        ) : (
          <Space>
            <Avatar size={size} style={{ fontSize }} src={user.profileImage}>
              {getFirstChar(user.firstName, user.lastName)}
            </Avatar>
            {fullName === true && <Typography.Text>{`${user?.firstName} ${user?.lastName}`}</Typography.Text>}
          </Space>
        )}
      </>
    )
  );
};

export default AvatarImg;
