export const theme = {
  components: {
    Button: {
      borderRadius: 15,
    },
    Modal: {
      Button: {
        borderRadius: 15,
      },
    },
  },
  token: {
    colorPrimary: "#c9184a",
    colorTextBase: "#272944",
    colorBgBase: "#F9F9FA",
    colorBgLayout: "#F9F9FA",
    colorBgContainer: "#FFFFFF",
    colorSecondary: "#ff758f",
    fontFamily: "Rubik",
    borderRadius: 12,
  },
};
