import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { API_URL } from "../../util/secrets";
import dayjs from "dayjs";

export const readTimelogTracker = createAsyncThunk("timelogTracker/read", async (date: Date) => {
  if (!date) return;
  const month = dayjs(date).format("MM");
  const year = dayjs(date).format("YYYY");
  const { data } = await axios.get(API_URL + `/admin/timlogSheet?month=${month}&year=${year}`);
  return data;
});

export const readTimelogTrackerByDate = createAsyncThunk("timelogTracker/read/bydate", async (date: Date) => {
  const { data } = await axios.get(API_URL + `/admin/timlogSheet?date=${dayjs(date).unix()}`);
  return data;
});

export const sendTimelogs = createAsyncThunk(
  "timelogTracker/send",
  async (timelogTracker: { payload: any; uid: string }) => {
    const { data } = await axios.post(
      API_URL + `/admin/operation/timesheet?uid=${timelogTracker.uid}`,
      timelogTracker.payload,
    );
    return data;
  },
);
