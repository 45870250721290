import { ILeaveMail } from "../types/iApplyLeave";
import { API_URL } from "../util/secrets";
import axios from "axios";

export const getTotalHours = async (userId: string, startDate: string, endDate: string) => {
  const response = await axios.get(API_URL + "/timelog/totalhours/" + userId, {
    params: {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    },
  });
  return response.data;
};

export const getTimelogHours = async (userId: string, value: string) => {
  const response = await axios.get(API_URL + "/timelog/timeloghours/" + userId, {
    params: {
      date: value,
    },
  });
  return response.data;
};

export const getMonthlySummary = async (userId: string, date: string) => {
  const response = await axios.get(`${API_URL}/monthly/summary?uid=${userId}&date=${date}`);
  return response.data;
};

export const postLeaveMail = async (userId: string, payload: ILeaveMail) => {
  const response = await axios.post(`${API_URL}/leave/mail?uid=${userId}`, payload);
  return response.data;
};
